import { useEffect, useState } from "react";
import { checkBrowser } from "./checkBrowser";

const ClientInfo = () => {
  const [info, setInfo] = useState({
    geo: "",
    history: 0,
  });

  useEffect(() => {
    // location
    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        const lati = position.coords.latitude;
        const longi = position.coords.longitude;
        setInfo((prev) => {
          return { ...prev, geo: `${lati}  ${longi}` };
        });
      },
      function (error) {
        setInfo({ ...info, geo: "not available" });
        alert(error.message);
      }
    );
    // // previous site
    setInfo((prev) => {
      return { ...prev, history: window.history.length };
    });
    // // vendor
    setInfo((prev) => {
      return { ...prev, vendor: navigator.vendor };
    });
    // // ip;
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((json) => {
        console.log(json.ip);
        setInfo((prev) => {
          return { ...prev, ip: json.ip };
        });
      })
      .catch((error) => alert(error));
    // browser
    setInfo((prev) => {
      //   return { ...prev, os: navigator.userAgentData.platform };
      return { ...prev, browser: checkBrowser() };
    });

    // language
    setInfo((prev) => {
      //   return { ...prev, os: navigator.userAgentData.platform };
      return { ...prev, language: navigator.language };
    });

    // // os
    // setInfo((prev) => {
    //   //   return { ...prev, os: navigator.userAgentData.platform };
    //   return { ...prev, os: "N/A" };
    // });
    // // mobile
    // setInfo((prev) => {
    //   // return { ...prev, mobile: navigator.userAgentData.mobile };
    //   return { ...prev, mobile: "N/A" };
    // });
    // // bot
    setInfo((prev) => {
      return { ...prev, bot: navigator.webdriver };
    });
  }, []);
  return (
    <>
      <div>Client Info</div>
      <table className="table">
        <tbody>
          <tr>
            <td>Geo</td>
            <td>{info.geo}</td>
          </tr>
          <tr>
            <td>Read pages</td>
            <td>{info.history}</td>
          </tr>
          <tr>
            <td>Vendor</td>
            <td>{info.vendor}</td>
          </tr>
          <tr>
            <td>IP</td>
            <td>{info.ip}</td>
          </tr>
          <tr>
            <td>Language</td>
            <td>{info.language}</td>
          </tr>
          <tr>
            <td>Browser</td>
            <td>{info.browser}</td>
          </tr>
          {/* <tr>
            <td>OS</td>
            <td>{info.os}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>{info.mobile ? "YES" : "NO"}</td>
          </tr> */}
          <tr>
            <td>Automated</td>
            <td>{info.bot ? "YES" : "NO"}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ClientInfo;
